/* Open Sans Webfont Kit
 * -------------------------- */
/* Light 300
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/light/opensans-light-webfont.eot');
  src: url('../fonts/light/opensans-light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/light/opensans-light-webfont.woff') format('woff'), url('../fonts/light/opensans-light-webfont.ttf') format('truetype'), url('../fonts/light/opensans-light-webfont.svg#OpenSansLight') format('svg');
  font-weight: 300;
  font-style: normal; }

/* Light 300 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/light-italic/opensans-light-italic-webfont.eot');
  src: url('../fonts/light-italic/opensans-light-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/light-italic/opensans-light-italic-webfont.woff') format('woff'), url('../fonts/light-italic/opensans-light-italic-webfont.ttf') format('truetype'), url('../fonts/light-italic/opensans-light-italic-webfont.svg#OpenSansLightItalic') format('svg');
  font-weight: 300;
  font-style: italic; }

/* Regular 400
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/regular/opensans-regular-webfont.eot');
  src: url('../fonts/regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/regular/opensans-regular-webfont.woff') format('woff'), url('../fonts/regular/opensans-regular-webfont.ttf') format('truetype'), url('../fonts/regular/opensans-regular-webfont.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal; }

/* Regular 400 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/italic/opensans-italic-webfont.eot');
  src: url('../fonts/italic/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/italic/opensans-italic-webfont.woff') format('woff'), url('../fonts/italic/opensans-italic-webfont.ttf') format('truetype'), url('../fonts/italic/opensans-italic-webfont.svg#OpenSansItalic') format('svg');
  font-weight: normal;
  font-style: italic; }

/* Semibold 600
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/semibold/opensans-semibold-webfont.eot');
  src: url('../fonts/semibold/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/semibold/opensans-semibold-webfont.woff') format('woff'), url('../fonts/semibold/opensans-semibold-webfont.ttf') format('truetype'), url('../fonts/semibold/opensans-semibold-webfont.svg#OpenSansSemibold') format('svg');
  font-weight: 600;
  font-style: normal; }

/* Semi-Bold 600 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/semibold-italic/opensans-semibold-italic-webfont.eot');
  src: url('../fonts/semibold-italic/opensans-semibold-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/semibold-italic/opensans-semibold-italic-webfont.woff') format('woff'), url('../fonts/semibold-italic/opensans-semibold-italic-webfont.ttf') format('truetype'), url('../fonts/semibold-italic/opensans-semibold-italic-webfont.svg#OpenSansSemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic; }

/* Bold 700
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/bold/opensans-bold-webfont.eot');
  src: url('../fonts/bold/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/bold/opensans-bold-webfont.woff') format('woff'), url('../fonts/bold/opensans-bold-webfont.ttf') format('truetype'), url('../fonts/bold/opensans-bold-webfont.svg#OpenSansBold') format('svg');
  font-weight: bold;
  font-style: normal; }

/* Bold 700 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/bold-italic/opensans-bold-italic-webfont.eot');
  src: url('../fonts/bold-italic/opensans-bold-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/bold-italic/opensans-bold-italic-webfont.woff') format('woff'), url('../fonts/bold-italic/opensans-bold-italic-webfont.ttf') format('truetype'), url('../fonts/bold-italic/opensans-bold-italic-webfont.svg#OpenSansItalic') format('svg');
  font-weight: bold;
  font-style: italic; }

/* Extrabold 800
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/extrabold/opensans-extrabold-webfont.eot');
  src: url('../fonts/extrabold/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/extrabold/opensans-extrabold-webfont.woff') format('woff'), url('../fonts/extrabold/opensans-extrabold-webfont.ttf') format('truetype'), url('../fonts/extrabold/opensans-extrabold-webfont.svg#OpenSansExtrabold') format('svg');
  font-weight: 800;
  font-style: normal; }

/* Extra-Bold 800 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/extrabold-italic/opensans-extrabold-italic-webfont.eot');
  src: url('../fonts/extrabold-italic/opensans-extrabold-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/extrabold-italic/opensans-extrabold-italic-webfont.woff') format('woff'), url('../fonts/extrabold-italic/opensans-extrabold-italic-webfont.ttf') format('truetype'), url('../fonts/extrabold-italic/opensans-extrabold-italic-webfont.svg#OpenSansExtraboldItalic') format('svg');
  font-weight: 800;
  font-style: italic; }
