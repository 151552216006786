.panel-group {

  .panel+.panel {
    margin-top: 0;
  }

  .panel.panel-default {
    border: none;
    background-color: transparent;
    .border-radius(0);
    .box-shadow-none;

    .panel-heading {
      background-color: transparent;
      border-bottom: 2px solid @color-grey-light-3;
      margin: 0;
      padding: 12px 15px 10px 15px;

      .panel-title {
        font-size: 16px;
        line-height: 16px;

        & > a {
          font-weight: 600;
          letter-spacing: -0.05em;
          text-transform: uppercase;
          color: @color-green;
          text-decoration: none;
          display:block;
          position: relative;
          width: 95%;

          & > span {
            width: 99%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .transition(all, 0.3s, ease);

          &:hover {
            color: @color-green;
          }

          &.collapsed {
            color: @color-grey;

            & > i {
              .transform-rotate(0deg);
            }

            &:hover {
              color: @color-grey-dark;
            }
          }

          & > i {
            font-size: 24px;
            .transform-rotate(180deg);
            .transition(all, 0.3s, ease);
            position: absolute;
            margin-top: -2px;
            right: -5%;

          }

        }

      }

      &+.panel-collapse>.list-group, &+.panel-collapse>.panel-body {
        border: none;
        margin-bottom: 2px;
      }

    }

  }

}