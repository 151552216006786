.dropdown-header {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: @color-green;
  text-transform: uppercase;
}

.dropdown-menu {
  .box-shadow(0, 1px, 5px, 0.1);
}

.dropdown-icon-toggle {
  text-decoration: none !important;
  &:hover {
    color: @color-green;
  }
  i {
    font-size: 24px;
  }
}