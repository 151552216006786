dt, kbd kbd, label {
  font-weight: 600;
}

.leap-form-horizontal {

  .form-group {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 0;
    position: relative;
    .transition(all, 0.3s, ease);

    &> .form-group-dual {
      width: 50%;
      float: left;
      position: relative;
      .transition(all, 0.3s, ease);
    }

    .control-label {
      font-weight:600;
      color:@color-grey;
      padding:12px 5px 12px 0;
      width: 150px;
    }

    .control-content {
      padding-top:12px;
      padding-bottom: 12px;

      .leap-label {
        margin-top: -12px;
        position: relative;
        bottom: 2px;
      }
    }

    .leap-form-action-button {
      position:absolute;
      top:11px;
      right:20px;
      font-size:18px;
      color: @color-green;
      display:none;

      i {
        font-size:18px;
        margin-left:20px;
        .transition(all, 0.3s, ease);

        &:hover {
          color: @color-green;
        }
      }

    }

  }

  .form-hover {
    &:hover, &:focus {
      background-color:@color-grey-light-1;
      cursor:pointer;

      .leap-form-action-button {
        display: block;
      }

    }
  }

}

/*

.leap-form-horizontal .control-group.group-hover:hover, .leap-form-horizontal .leap-group-dual.group-hover:hover {
  background-color:#cce6f7;
  cursor:pointer;
}

.leap-form-horizontal .control-group.group-hover:hover .leap-form-action-button, .leap-form-horizontal .leap-group-dual.group-hover:hover .leap-form-action-button {
  display:block;
}

.leap-form-horizontal .control-label .fa {
  font-size:18px;
  margin-left:20px;
}

.leap-form-horizontal .controls {
  position:relative;
  margin-left:190px;
}

.leap-form-horizontal .controls span {
  margin:17px 0;
  display:inline-block;
}

.leap-form-horizontal .controls .label {
  margin:13px 0 0 0;
}

.leap-form-action-button {
  position:absolute;
  top:17px;
  right:20px;
  font-size:18px;
  color: #0060aa;
  display:none;
}

*/