/* #################################### BORDERS #################################### */

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.border-radius-custom (@topleft: 5px, @topright: 5px, @bottomleft: 5px, @bottomright: 5px) {
  -webkit-border-radius: @topleft @topright @bottomleft @bottomright;
  -moz-border-radius: @topleft @topright @bottomleft @bottomright;
  border-radius: @topleft @topright @bottomleft @bottomright ;
}

/* #################################### SHADOWS #################################### */

.box-shadow (@x: 0px, @y: 3px, @blur: 5px, @alpha: 0.5) {
  -webkit-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
  -moz-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
  box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
}

.box-shadow-none  {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/* #################################### TRANSITIONS #################################### */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/* #################################### TRANSFORM #################################### */

.transform (@rotate: 90deg, @scale: 1, @skew: 1deg, @translate: 10px) {
  -webkit-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
  -moz-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
  -o-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
  -ms-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
  transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
}

.transform-rotate (@rotate: 90deg) {
  -webkit-transform: rotate(@rotate);
  -moz-transform: rotate(@rotate);
  -o-transform: rotate(@rotate);
  -ms-transform: rotate(@rotate);
  transform: rotate(@rotate);
}

/* #################################### GRADIENTS #################################### */

.gradient (@origin: left, @start: #ffffff, @stop: #000000) {
  background-color: @start;
  background-image: -webkit-linear-gradient(@origin, @start, @stop);
  background-image: -moz-linear-gradient(@origin, @start, @stop);
  background-image: -o-linear-gradient(@origin, @start, @stop);
  background-image: -ms-linear-gradient(@origin, @start, @stop);
  background-image: linear-gradient(@origin, @start, @stop);
}

.quick-gradient (@origin: left, @alpha: 0.2) {
  background-image: -webkit-linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
  background-image: -moz-linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
  background-image: -o-linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
  background-image: -ms-linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
  background-image: linear-gradient(@origin, rgba(0,0,0,0.0), rgba(0,0,0,@alpha));
}

.radial-gradient(@start: #ffffff, @stop: #000000) {
  background: -moz-radial-gradient(center, circle cover, @start 0%, @stop 75%);
  background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%, @start), color-stop(75%, @stop));
  background: -webkit-radial-gradient(center, circle cover, @start 0%, @stop 75%);
  background: -o-radial-gradient(center, circle cover, @start 0%, @stop 75%);
  background: -ms-radial-gradient(center, circle cover, @start 0%, @stop 75%);
  background: radial-gradient(center, circle cover, @start 0%, @stop 75%);
  background-color: @start;
}

/* #################################### BACKGROUND #################################### */

.background-size (@origin: cover) {
  -webkit-background-size: @origin;
  -moz-background-size: @origin;
  -o-background-size: @origin;
  background-size: @origin;
}

.grayscale (@origin: 100%) {
  -webkit-filter: grayscale(@origin);
  filter: grayscale(@origin);
  filter: gray;
  filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
}

/* #################################### OPACITY #################################### */

.opacity(@opacity) {
  opacity: @opacity;
  @opacity-ie: @opacity * 100;
  filter: alpha(opacity=@opacity-ie); //IE8
}
.blur(@blur) {
  -webkit-filter: blur(@blur);
  -moz-filter: blur(@blur);
  -o-filter: blur(@blur);
  -ms-filter: blur(@blur);
  filter: blur(@blur);
}
