button:focus {
  outline: none;
  box-shadow: none;
}

.btn {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  padding: 12px 30px;
  letter-spacing: 0.02em;
  .border-radius(3px);
  .box-shadow-none;
  .transition(all, 0.3s ease);

  &.active.focus, &.active:focus, &.focus, &.active.focus, &:active:focus, &:focus {
    outline: none;
    box-shadow: none;
  }

  .badge, .label {
    position: relative;
    top: -2px;
  }

  .leapicon {
    font-size:16px;
    position: relative;
    top: 2px;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    line-height: 0.5;

  }

  &.btn-xs {
    font-size:10px;
    padding:10px;
    line-height: 10px;

    .leapicon {
      font-size:14px;
    }

  }

  &.btn-sm {
    font-size:11px;
    padding:10px 20px;
    line-height: 11px;
    letter-spacing:0.02em;

    .leapicon {
      font-size:15px;
    }

  }

  &.btn-lg {
    font-size:15px;
    padding: 15px 50px;
    line-height: 15px;
    letter-spacing: 0.01em;

    .leapicon {
      font-size:19px;
      top: 3px;
    }

  }

  &.btn-primary,
  &.btn-success,
  &.btn-info,
  &.btn-warning {
    border-color:transparent
  }

  &.dropdown-toggle > .caret {
    margin-bottom: 1px;
  }

}

.btn-group {
  &.open {
    .dropdown-toggle {
      .box-shadow-none;
    }
  }
}