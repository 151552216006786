.table {

  tbody > tr > td, tbody > tr > th, > tfoot > tr > td, tfoot > tr > th, thead > tr > td, thead > tr > th {
    line-height: 20px;
    text-align: left;
    vertical-align: top;
    .transition(all, 0.3s ease);
  }

  thead > tr > th {
    font-weight: 600;
    font-size: 14px;
    color: @color-grey;
    text-transform: uppercase;
    padding: 15px auto;
    border-top: none;
    //border-bottom-color: @color-blue;
  }

  tfoot > tr > th {
    font-weight: 600;
    color: @color-green;
  }

  .leap-td-divider {
    width: 2.5%;
  }

  &.table-hover tbody tr:hover > td,
  &.table-hover tbody tr:hover > th,
  &.table-hover tfoot tr:hover > td,
  &.table-hover tfoot tr:hover > th {
    background-color: @color-grey-light-1;
  }

  &.table-hover tbody tr:hover > td.leap-lightgrey2-bg,
  &.table-hover tbody tr:hover > th.leap-lightgrey2-bg,
  &.table-hover tfoot tr:hover > td.leap-lightgrey2-bg,
  &.table-hover tfoot tr:hover > th.leap-lightgrey2-bg {
    background-color: darken(@color-grey-light-1, 3%);
  }

  .text-center {
    display: block;
  }

  .leap-th-group th {
    border-bottom: none;
  }

  .leap-tr-total td {
    background-color: @color-green-light;
    border-top: 2px solid @color-green;
  }

  .leap-tr-total strong, .leap-tr-ballance strong {
    font-size: 15px;
  }

  .leap-tr-total td.leap-lightgrey2-bg {
    background-color: darken(@color-green-light, 3%);
  }

  &.table-extended {

    tbody > tr > td, tbody > tr > th, > tfoot > tr > td, tfoot > tr > th, thead > tr > td, thead > tr > th {
      line-height: 20px;
      text-align: left;
      vertical-align: top;
      padding: 15px 5px;
      .transition(all, 0.3s ease);
    }

  }

}



