/*! Generated by Live LESS Theme Customizer */

@gray-base: rgb(47, 47, 47);
@gray-darker: lighten(@gray-base, 13.5%);
@gray-dark: lighten(@gray-base, 20%);
@gray: lighten(@gray-base, 33.5%);
@gray-light: lighten(@gray-base, 46.7%);
@gray-lighter: lighten(@gray-base, 93.5%);
@brand-primary: #2f2f2f;
@brand-success: #3FAF2A;
@brand-info: #999;
@brand-warning: rgb(246, 145, 57);
@brand-danger: rgb(192, 46, 33);
@body-bg: rgb(238, 238, 238);
@text-color: @gray-dark;
@link-color: @brand-primary;
@link-hover-color: darken(@link-color, 15%);
@link-hover-decoration: underline;
@font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
@font-family-serif: Georgia, "Times New Roman", Times, serif;
@font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base: @font-family-sans-serif;
@font-size-base: 14px;
@font-size-large: ceil((@font-size-base * 1.25));
@font-size-small: ceil((@font-size-base * 0.85));
@font-size-h1: floor((@font-size-base * 2.6));
@font-size-h2: floor((@font-size-base * 2.15));
@font-size-h3: ceil((@font-size-base * 1.7));
@font-size-h4: ceil((@font-size-base * 1.25));
@font-size-h5: @font-size-base;
@font-size-h6: ceil((@font-size-base * 0.85));
@line-height-base: 1.428571429;
@line-height-computed: floor((@font-size-base * @line-height-base));
@headings-font-family: inherit;
@headings-font-weight: 500;
@headings-line-height: 1.1;
@headings-color: @brand-success;
@icon-font-path: "../fonts/";
@icon-font-name: "glyphicons-halflings-regular";
@icon-font-svg-id: "glyphicons_halflingsregular";
@padding-base-vertical: 6px;
@padding-base-horizontal: 12px;
@padding-large-vertical: 10px;
@padding-large-horizontal: 16px;
@padding-small-vertical: 5px;
@padding-small-horizontal: 10px;
@padding-xs-vertical: 1px;
@padding-xs-horizontal: 5px;
@line-height-large: 1.3333333;
@line-height-small: 1.5;
@border-radius-base: 3px;
@border-radius-large: 3px;
@border-radius-small: 2px;
@component-active-color: rgb(255, 255, 255);
@component-active-bg: @brand-primary;
@caret-width-base: 4px;
@caret-width-large: 5px;
@table-cell-padding: 8px;
@table-condensed-cell-padding: 5px;
@table-bg: transparent;
@table-bg-accent: rgb(249, 249, 249);
@table-bg-hover: rgb(245, 245, 245);
@table-bg-active: @table-bg-hover;
@table-border-color: rgb(221, 221, 221);
@btn-font-weight: 600;
@btn-default-color: rgb(51, 51, 51);
@btn-default-bg: rgb(255, 255, 255);
@btn-default-border: rgb(204, 204, 204);
@btn-primary-color: rgb(255, 255, 255);
@btn-primary-bg: @brand-primary;
@btn-primary-border: darken(@btn-primary-bg, 5%);
@btn-success-color: rgb(255, 255, 255);
@btn-success-bg: @brand-success;
@btn-success-border: darken(@btn-success-bg, 5%);
@btn-info-color: rgb(255, 255, 255);
@btn-info-bg: @brand-info;
@btn-info-border: darken(@btn-info-bg, 5%);
@btn-warning-color: rgb(255, 255, 255);
@btn-warning-bg: @brand-warning;
@btn-warning-border: darken(@btn-warning-bg, 5%);
@btn-danger-color: rgb(255, 255, 255);
@btn-danger-bg: @brand-danger;
@btn-danger-border: darken(@btn-danger-bg, 5%);
@btn-link-disabled-color: @gray-light;
@btn-border-radius-base: @border-radius-base;
@btn-border-radius-large: @border-radius-large;
@btn-border-radius-small: @border-radius-small;
@input-bg: rgb(255, 255, 255);
@input-bg-disabled: @gray-lighter;
@input-color: @gray;
@input-border: rgb(216, 216, 216);
@input-border-radius: @border-radius-base;
@input-border-radius-large: @border-radius-large;
@input-border-radius-small: @border-radius-small;
@input-border-focus: @brand-primary;
@input-color-placeholder: #999;
@input-height-base: (@line-height-computed + (@padding-base-vertical * 2) + 2);
@input-height-large: (ceil(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
@input-height-small: (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);
@form-group-margin-bottom: 15px;
@legend-color: @gray-dark;
@legend-border-color: rgb(229, 229, 229);
@input-group-addon-bg: @gray-lighter;
@input-group-addon-border-color: @input-border;
@cursor-disabled: not-allowed;
@dropdown-bg: rgb(255, 255, 255);
@dropdown-border: rgb(216, 216, 216);
@dropdown-fallback-border: rgb(216, 216, 216);
@dropdown-divider-bg: rgb(216, 216, 216);
@dropdown-link-color: @gray-dark;
@dropdown-link-hover-color: #fff;
@dropdown-link-hover-bg: @brand-primary;
@dropdown-link-active-color: @component-active-color;
@dropdown-link-active-bg: @component-active-bg;
@dropdown-link-disabled-color: @gray-light;
@dropdown-header-color: @gray-light;
@dropdown-caret-color: rgb(0, 0, 0);
@zindex-navbar: 1000;
@zindex-dropdown: 1000;
@zindex-popover: 1060;
@zindex-tooltip: 1070;
@zindex-navbar-fixed: 1030;
@zindex-modal-background: 1040;
@zindex-modal: 1050;
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
@screen-phone: @screen-xs-min;
@screen-sm: 768px;
@screen-sm-min: @screen-sm;
@screen-tablet: @screen-sm-min;
@screen-md: 992px;
@screen-md-min: @screen-md;
@screen-desktop: @screen-md-min;
@screen-lg: 1200px;
@screen-lg-min: @screen-lg;
@screen-lg-desktop: @screen-lg-min;
@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);
@grid-columns: 12;
@grid-gutter-width: 30px;
@grid-float-breakpoint: @screen-sm-min;
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);
@container-tablet: (720px + @grid-gutter-width);
@container-sm: @container-tablet;
@container-desktop: (940px + @grid-gutter-width);
@container-md: @container-desktop;
@container-large-desktop: (1140px + @grid-gutter-width);
@container-lg: @container-large-desktop;
@navbar-height: 50px;
@navbar-margin-bottom: @line-height-computed;
@navbar-border-radius: @border-radius-base;
@navbar-padding-horizontal: floor((@grid-gutter-width / 2));
@navbar-padding-vertical: ((@navbar-height - @line-height-computed) / 2);
@navbar-collapse-max-height: 340px;
@navbar-default-color: #999;
@navbar-default-bg: @brand-primary;
@navbar-default-border: darken(@navbar-default-bg, 6.5%);
@navbar-default-link-color: #fff;
@navbar-default-link-hover-color: @brand-success;
@navbar-default-link-hover-bg: transparent;
@navbar-default-link-active-color: @brand-success;
@navbar-default-link-active-bg: darken(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-color: rgb(204, 204, 204);
@navbar-default-link-disabled-bg: transparent;
@navbar-default-brand-color: @navbar-default-link-color;
@navbar-default-brand-hover-color: darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg: transparent;
@navbar-default-toggle-hover-bg: rgb(221, 221, 221);
@navbar-default-toggle-icon-bar-bg: rgb(136, 136, 136);
@navbar-default-toggle-border-color: rgb(221, 221, 221);
@navbar-inverse-color: lighten(@gray-light, 15%);
@navbar-inverse-bg: @brand-success;
@navbar-inverse-border: @brand-success;
@navbar-inverse-link-color: rgb(255, 255, 255);
@navbar-inverse-link-hover-color: rgb(255, 255, 255);
@navbar-inverse-link-hover-bg: transparent;
@navbar-inverse-link-active-color: #fff;
@navbar-inverse-link-active-bg: darken(@navbar-inverse-bg, 6%);
@navbar-inverse-link-disabled-color: darken(@navbar-inverse-bg, 6%);
@navbar-inverse-link-disabled-bg: transparent;
@navbar-inverse-brand-color: @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color: rgb(255, 255, 255);
@navbar-inverse-brand-hover-bg: transparent;
@navbar-inverse-toggle-hover-bg: rgb(51, 51, 51);
@navbar-inverse-toggle-icon-bar-bg: rgb(255, 255, 255);
@navbar-inverse-toggle-border-color: rgb(51, 51, 51);
@nav-link-padding: 10px 15px;
@nav-link-hover-bg: @gray-lighter;
@nav-disabled-link-color: @gray-light;
@nav-disabled-link-hover-color: @gray-light;
@nav-tabs-border-color: rgb(216, 216, 216);
@nav-tabs-link-hover-border-color: @gray-lighter;
@nav-tabs-active-link-hover-bg: @body-bg;
@nav-tabs-active-link-hover-color: @gray;
@nav-tabs-active-link-hover-border-color: rgb(216, 216, 216);
@nav-tabs-justified-link-border-color: rgb(216, 216, 216);
@nav-tabs-justified-active-link-border-color: @body-bg;
@nav-pills-border-radius: @border-radius-base;
@nav-pills-active-link-hover-bg: @component-active-bg;
@nav-pills-active-link-hover-color: @component-active-color;
@pagination-color: @link-color;
@pagination-bg: rgb(255, 255, 255);
@pagination-border: rgb(216, 216, 216);
@pagination-hover-color: @link-hover-color;
@pagination-hover-bg: @gray-lighter;
@pagination-hover-border: rgb(216, 216, 216);
@pagination-active-color: rgb(255, 255, 255);
@pagination-active-bg: @brand-primary;
@pagination-active-border: @brand-primary;
@pagination-disabled-color: @gray-light;
@pagination-disabled-bg: rgb(255, 255, 255);
@pagination-disabled-border: rgb(216, 216, 216);
@pager-bg: @pagination-bg;
@pager-border: @pagination-border;
@pager-border-radius: 15px;
@pager-hover-bg: @pagination-hover-bg;
@pager-active-bg: @pagination-active-bg;
@pager-active-color: @pagination-active-color;
@pager-disabled-color: @pagination-disabled-color;
@jumbotron-padding: 30px;
@jumbotron-color: inherit;
@jumbotron-bg: @gray-lighter;
@jumbotron-heading-color: inherit;
@jumbotron-font-size: ceil((@font-size-base * 1.5));
@jumbotron-heading-font-size: ceil((@font-size-base * 4.5));
@state-success-text: @brand-success;
@state-success-bg: lighten(@brand-success, 50%);
@state-success-border: darken(spin(@state-success-bg, -10), 5%);
@state-info-text: rgb(0, 96, 170);
@state-info-bg: rgb(204, 230, 247);
@state-info-border: darken(spin(@state-info-bg, -10), 7%);
@state-warning-text: rgb(138, 109, 59);
@state-warning-bg: rgb(252, 248, 227);
@state-warning-border: darken(spin(@state-warning-bg, -10), 5%);
@state-danger-text: rgb(192, 46, 33);
@state-danger-bg: rgb(242, 222, 222);
@state-danger-border: darken(spin(@state-danger-bg, -10), 5%);
@tooltip-max-width: 200px;
@tooltip-color: rgb(255, 255, 255);
@tooltip-bg: @brand-success;
@tooltip-opacity: .9;
@tooltip-arrow-width: 5px;
@tooltip-arrow-color: @tooltip-bg;
@popover-bg: rgb(255, 255, 255);
@popover-max-width: 276px;
@popover-border-color: rgba(0, 0, 0, 0.2);
@popover-fallback-border-color: rgb(204, 204, 204);
@popover-title-bg: darken(@popover-bg, 3%);
@popover-arrow-width: 10px;
@popover-arrow-color: @popover-bg;
@popover-arrow-outer-width: (@popover-arrow-width + 1);
@popover-arrow-outer-color: fadein(@popover-border-color, 5%);
@popover-arrow-outer-fallback-color: darken(@popover-fallback-border-color, 20%);
@label-default-bg: @gray-light;
@label-primary-bg: @brand-primary;
@label-success-bg: @brand-success;
@label-info-bg: @brand-info;
@label-warning-bg: @brand-warning;
@label-danger-bg: @brand-danger;
@label-color: rgb(255, 255, 255);
@label-link-hover-color: rgb(255, 255, 255);
@modal-inner-padding: 15px;
@modal-title-padding: 15px;
@modal-title-line-height: @line-height-base;
@modal-content-bg: rgb(255, 255, 255);
@modal-content-border-color: rgba(255, 255, 255, 0.5);
@modal-content-fallback-border-color: rgb(216, 216, 216);
@modal-backdrop-bg: darken(@brand-primary, 20%);
@modal-backdrop-opacity: .2;
@modal-header-border-color: rgb(229, 229, 229);
@modal-footer-border-color: @modal-header-border-color;
@modal-lg: 900px;
@modal-md: 600px;
@modal-sm: 300px;
@alert-padding: 15px;
@alert-border-radius: @border-radius-base;
@alert-link-font-weight: bold;
@alert-success-bg: @state-success-bg;
@alert-success-text: @state-success-text;
@alert-success-border: @state-success-border;
@alert-info-bg: @state-info-bg;
@alert-info-text: @state-info-text;
@alert-info-border: @state-info-border;
@alert-warning-bg: @state-warning-bg;
@alert-warning-text: @state-warning-text;
@alert-warning-border: @state-warning-border;
@alert-danger-bg: @state-danger-bg;
@alert-danger-text: @state-danger-text;
@alert-danger-border: @state-danger-border;
@progress-bg: rgb(245, 245, 245);
@progress-bar-color: rgb(255, 255, 255);
@progress-border-radius: @border-radius-base;
@progress-bar-bg: @brand-primary;
@progress-bar-success-bg: @brand-success;
@progress-bar-warning-bg: @brand-warning;
@progress-bar-danger-bg: @brand-danger;
@progress-bar-info-bg: @brand-info;
@list-group-bg: rgb(255, 255, 255);
@list-group-border: rgb(221, 221, 221);
@list-group-border-radius: @border-radius-base;
@list-group-hover-bg: rgb(245, 245, 245);
@list-group-active-color: @component-active-color;
@list-group-active-bg: @component-active-bg;
@list-group-active-border: @list-group-active-bg;
@list-group-active-text-color: lighten(@list-group-active-bg, 40%);
@list-group-disabled-color: @gray-light;
@list-group-disabled-bg: @gray-lighter;
@list-group-disabled-text-color: @list-group-disabled-color;
@list-group-link-color: rgb(85, 85, 85);
@list-group-link-hover-color: @list-group-link-color;
@list-group-link-heading-color: rgb(51, 51, 51);
@panel-bg: rgb(255, 255, 255);
@panel-body-padding: 15px;
@panel-heading-padding: 10px 15px;
@panel-footer-padding: @panel-heading-padding;
@panel-border-radius: @border-radius-base;
@panel-inner-border: rgb(221, 221, 221);
@panel-footer-bg: rgb(245, 245, 245);
@panel-default-text: @gray-dark;
@panel-default-border: rgb(221, 221, 221);
@panel-default-heading-bg: rgb(245, 245, 245);
@panel-primary-text: rgb(255, 255, 255);
@panel-primary-border: @brand-primary;
@panel-primary-heading-bg: @brand-primary;
@panel-success-text: @state-success-text;
@panel-success-border: @state-success-border;
@panel-success-heading-bg: @state-success-bg;
@panel-info-text: @state-info-text;
@panel-info-border: @state-info-border;
@panel-info-heading-bg: @state-info-bg;
@panel-warning-text: @state-warning-text;
@panel-warning-border: @state-warning-border;
@panel-warning-heading-bg: @state-warning-bg;
@panel-danger-text: @state-danger-text;
@panel-danger-border: @state-danger-border;
@panel-danger-heading-bg: @state-danger-bg;
@thumbnail-padding: 4px;
@thumbnail-bg: @body-bg;
@thumbnail-border: rgb(221, 221, 221);
@thumbnail-border-radius: @border-radius-base;
@thumbnail-caption-color: @text-color;
@thumbnail-caption-padding: 9px;
@well-bg: rgb(245, 245, 245);
@well-border: darken(@well-bg, 7%);
@badge-color: rgb(255, 255, 255);
@badge-link-hover-color: rgb(255, 255, 255);
@badge-bg: @brand-success;
@badge-active-color: @link-color;
@badge-active-bg: rgb(255, 255, 255);
@badge-font-weight: bold;
@badge-line-height: 1.1;
@badge-border-radius: 3px;
@breadcrumb-padding-vertical: 8px;
@breadcrumb-padding-horizontal: 15px;
@breadcrumb-bg: rgb(238, 238, 238);
@breadcrumb-color: rgb(204, 204, 204);
@breadcrumb-active-color: @gray-light;
@breadcrumb-separator: "/";
@carousel-text-shadow: 0 1px 2px rgba(0,0,0,.6);
@carousel-control-color: rgb(255, 255, 255);
@carousel-control-width: 15%;
@carousel-control-opacity: .5;
@carousel-control-font-size: 20px;
@carousel-indicator-active-bg: rgb(255, 255, 255);
@carousel-indicator-border-color: rgb(255, 255, 255);
@carousel-caption-color: rgb(255, 255, 255);
@close-font-weight: bold;
@close-color: rgb(0, 0, 0);
@close-text-shadow: 0 1px 0 #fff;
@code-color: rgb(199, 37, 78);
@code-bg: rgb(249, 242, 244);
@kbd-color: rgb(255, 255, 255);
@kbd-bg: rgb(51, 51, 51);
@pre-bg: rgb(245, 245, 245);
@pre-color: @gray-dark;
@pre-border-color: rgb(204, 204, 204);
@pre-scrollable-max-height: 340px;
@component-offset-horizontal: 180px;
@text-muted: @gray-light;
@abbr-border-color: @gray-light;
@headings-small-color: @gray-light;
@blockquote-small-color: @gray-light;
@blockquote-font-size: (@font-size-base * 1.25);
@blockquote-border-color: @gray-lighter;
@page-header-border-color: @gray-lighter;
@dl-horizontal-offset: @component-offset-horizontal;
@hr-border: @gray-lighter;

