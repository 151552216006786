.leap-heading-nav {
  & > a {
    text-decoration: none;

    .leap-back-button {
      position: relative;
      top: 2px;
      margin-right: 5px;
    }

    .leap-next-button {
      position: relative;
      top: 2px;
      margin-left: 5px;
    }

  }

}