@font-face {
  font-family: 'icomoon';
  src:url('../fonts/icomoon.eot?fulr11');
  src:url('../fonts/icomoon.eot?#iefixfulr11') format('embedded-opentype'),
  url('../fonts/icomoon.woff2?fulr11') format('woff2'),
  url('../fonts/icomoon.ttf?fulr11') format('truetype'),
  url('../fonts/icomoon.woff?fulr11') format('woff'),
  url('../fonts/icomoon.svg?fulr11#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="leapicon-"], [class*=" leapicon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.leapicon-logo-r:before {
  content: "\e600";
}
.leapicon-logo-wb .path1:before {
  content: "\e62d";
  color: rgb(255, 255, 255);
}
.leapicon-logo-wb .path2:before {
  content: "\e62e";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-wb .path3:before {
  content: "\e62f";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-wb .path4:before {
  content: "\e630";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-wb .path5:before {
  content: "\e631";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-wb .path6:before {
  content: "\e632";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-wb .path7:before {
  content: "\e633";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-wb .path8:before {
  content: "\e634";
  margin-left: -3.6611328125em;
  color: rgb(61, 57, 53);
}
.leapicon-logo-wb .path9:before {
  content: "\e635";
  margin-left: -3.6611328125em;
  color: rgb(61, 57, 53);
}
.leapicon-logo-wb .path10:before {
  content: "\e636";
  margin-left: -3.6611328125em;
  color: rgb(61, 57, 53);
}
.leapicon-logo-gw .path1:before {
  content: "\e619";
  color: rgb(77, 172, 58);
}
.leapicon-logo-gw .path2:before {
  content: "\e61a";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gw .path3:before {
  content: "\e61b";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gw .path4:before {
  content: "\e61c";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gw .path5:before {
  content: "\e61d";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gw .path6:before {
  content: "\e61e";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gw .path7:before {
  content: "\e61f";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gw .path8:before {
  content: "\e620";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-gw .path9:before {
  content: "\e621";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-gw .path10:before {
  content: "\e622";
  margin-left: -3.6611328125em;
  color: rgb(255, 255, 255);
}
.leapicon-logo-gb .path1:before {
  content: "\e623";
  color: rgb(77, 172, 58);
}
.leapicon-logo-gb .path2:before {
  content: "\e624";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gb .path3:before {
  content: "\e625";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gb .path4:before {
  content: "\e626";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gb .path5:before {
  content: "\e627";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gb .path6:before {
  content: "\e628";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gb .path7:before {
  content: "\e629";
  margin-left: -3.6611328125em;
  color: rgb(77, 172, 58);
}
.leapicon-logo-gb .path8:before {
  content: "\e62a";
  margin-left: -3.6611328125em;
  color: rgb(61, 57, 53);
}
.leapicon-logo-gb .path9:before {
  content: "\e62b";
  margin-left: -3.6611328125em;
  color: rgb(61, 57, 53);
}
.leapicon-logo-gb .path10:before {
  content: "\e62c";
  margin-left: -3.6611328125em;
  color: rgb(61, 57, 53);
}
.leapicon-comment-o:before {
  content: "\f0e5";
}
.leapicon-comments-o:before {
  content: "\f0e6";
}
.leapicon-telephone:before {
  content: "\e616";
}
.leapicon-magnifier-icon:before {
  content: "\e617";
}
.leapicon-bell-icon:before {
  content: "\e618";
}
.leapicon-cog:before {
  content: "\f013";
}
.leapicon-profile:before {
  content: "\e601";
}
.leapicon-error_outline:before {
  content: "\e001";
}
.leapicon-loop:before {
  content: "\e00e";
}
.leapicon-not_interested:before {
  content: "\e017";
}
.leapicon-pause_circle_outline:before {
  content: "\e01a";
}
.leapicon-play_circle_outline:before {
  content: "\e01d";
}
.leapicon-repeat:before {
  content: "\e023";
}
.leapicon-add:before {
  content: "\e069";
}
.leapicon-add_circle:before {
  content: "\e06b";
}
.leapicon-add_circle_outline:before {
  content: "\e06c";
}
.leapicon-content_paste:before {
  content: "\e073";
}
.leapicon-drafts:before {
  content: "\e075";
}
.leapicon-mail:before {
  content: "\e07c";
}
.leapicon-remove_circle:before {
  content: "\e080";
}
.leapicon-remove_circle_outline:before {
  content: "\e081";
}
.leapicon-keyboard_arrow_down:before {
  content: "\e10f";
}
.leapicon-keyboard_arrow_left:before {
  content: "\e110";
}
.leapicon-keyboard_arrow_right:before {
  content: "\e111";
}
.leapicon-keyboard_arrow_up:before {
  content: "\e112";
}
.leapicon-edit:before {
  content: "\e15e";
}
.leapicon-timer:before {
  content: "\e1b6";
}
.leapicon-wb_cloudy:before {
  content: "\e1be";
}
.leapicon-local_print_shop:before {
  content: "\e1ed";
}
.leapicon-place:before {
  content: "\e1f7";
}
.leapicon-arrow_back:before {
  content: "\e200";
}
.leapicon-arrow_forward:before {
  content: "\e204";
}
.leapicon-check:before {
  content: "\e206";
}
.leapicon-close:before {
  content: "\e209";
}
.leapicon-keyboard_control:before {
  content: "\e20f";
}
.leapicon-more_vert:before {
  content: "\e210";
}
.leapicon-refresh:before {
  content: "\e211";
}
.leapicon-sync:before {
  content: "\e22b";
}
.leapicon-radio_button_unchecked:before {
  content: "\e25e";
}
.leapicon-radio_button_checked:before {
  content: "\e25f";
}
.leapicon-account_balance:before {
  content: "\e265";
}
.leapicon-info_outline:before {
  content: "\e2a3";
}
.leapicon-launch:before {
  content: "\e2a9";
}
.leapicon-power_settings_new:before {
  content: "\e2c0";
}
.leapicon-schedule:before {
  content: "\e2c9";
}
.leapicon-help_outline:before {
  content: "\e30f";
}
.leapicon-chevron-thin-down:before {
  content: "\e605";
}
.leapicon-chevron-thin-left:before {
  content: "\e606";
}
.leapicon-chevron-thin-right:before {
  content: "\e607";
}
.leapicon-chevron-thin-up:before {
  content: "\e608";
}
.leapicon-help:before {
  content: "\e604";
}
.leapicon-lock-open:before {
  content: "\e602";
}
.leapicon-lock:before {
  content: "\e603";
}
.leapicon-user:before {
  content: "\e971";
}
.leapicon-users:before {
  content: "\e972";
}
.leapicon-user-plus:before {
  content: "\e973";
}
.leapicon-user-minus:before {
  content: "\e974";
}
.leapicon-user-check:before {
  content: "\e975";
}
.leapicon-filter:before {
  content: "\ea5b";
}
.leapicon-clipboard-icon:before {
  content: "\e609";
}
.leapicon-email-icon-2:before {
  content: "\e60a";
}
.leapicon-email-icon-3:before {
  content: "\e60b";
}
.leapicon-id-card:before {
  content: "\e60c";
}
.leapicon-note-icon:before {
  content: "\e60d";
}
.leapicon-paperclip-icon:before {
  content: "\e60e";
}
.leapicon-video-play-icon:before {
  content: "\e60f";
}
.leapicon-coin-icon:before {
  content: "\e610";
}
.leapicon-win-arrow-left:before {
  content: "\e611";
}
.leapicon-win-arrow-right:before {
  content: "\e612";
}
.leapicon-calendar:before {
  content: "\e613";
}
.leapicon-pause-icon:before {
  content: "\e614";
}
.leapicon-stop-icon:before {
  content: "\e615";
}
