.badge {
  display: inline-block;
  min-width: 10px;
  padding: 2px 7px;
  font-size: 11px;
  color: @color-white;
  line-height: 1.1;
  vertical-align: middle;
  background-color: @color-green;
  .border-radius(15px)
}