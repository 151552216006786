// Global
// --------------------------------------------------

body {
  color: @color-grey-dark;

  a {
    .transition(all, 0.3s, ease);
  }

  b, optgroup, strong {
    font-weight: 600;
  }

  input.form-control, input.form-control:focus, .form-control:focus {
    .box-shadow-none;
  }

  .form-control:focus, .bootstrap-select .btn:focus, textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="weak"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
    border-color: @color-green;
    outline: none !important;
  }

  input[type=text], input[type="password"] {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .well {
    .box-shadow-none;
    border: none;
  }

  .leap-icon-color {
    letter-spacing: 0 !important;

    span, span:before {
      letter-spacing: 0 !important;
    }
  }

}

// Scrollbar
// --------------------------------------------------

::-webkit-scrollbar {
  width:8px;
  height:0;
}
::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment {
  display:none
}
::-webkit-scrollbar-track-piece {
  background-color:@color-grey-light-2;
}
::-webkit-scrollbar-thumb {
  border-radius:5px;
  background-color:@color-grey-light-3;
}

// Background Color
// --------------------------------------------------

.leap-white-bg {
  background-color:@color-white;
}

.leap-lightgrey2-bg {
  background-color:@color-grey-light-2;
}

.leap-lightgrey1-bg {
  background-color:@color-grey-light-1;
}

.leap-lightgreen-bg {
  background-color:@color-green-light;
}

.leap-green-bg {
  background-color:@color-green;
}

.leap-grey-bg {
  background-color:@color-grey;
}

.leap-darkgrey-bg {
  background-color:@color-grey-dark;
}

// Text Color
// --------------------------------------------------

.leap-white-color {
  color:@color-white;
}

.leap-lightgrey2-color {
  color:@color-grey-light-2;
}

.leap-lightgrey1-color {
  color:@color-grey-light-1;
}

.leap-lightgreen-color {
  color:@color-green-light;
}

.leap-green-color {
  color:@color-green;
}

.leap-grey-color {
  color:@color-grey;
}

.leap-darkgrey-color {
  color:@color-grey-dark;
}