@t-color0: #cccccc;
@t-color1: #ffffff;
@t-color2: @brand-success;
@t-color3: @brand-danger;
@t-color4: @brand-warning;
@t-color5: @brand-info;
@t-width0: 400px;
@t-width1: 100%;
@t-width2: 96%;

@t-height1: 4px;

.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  a {
    color: @t-color1;
    &:hover {
      color: @t-color0;
      text-decoration: none;
    }
  }
  label {
    color: @t-color1;
  }
}

.toast-close-button {
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  color: @t-color1;
  filter: alpha(opacity=80);
  float: right;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.8;
  position: relative;
  right: -0.0em;
  top: -0.6em;
  &:hover {
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    color: @t-color0;
    cursor: pointer;
    filter: alpha(opacity=40);
    opacity: 0.4;
    text-decoration: none;
  }
  &:focus {
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    color: @t-color0;
    cursor: pointer;
    filter: alpha(opacity=40);
    opacity: 0.4;
    text-decoration: none;
  }
}

button.toast-close-button {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #88909b;
  cursor: pointer;
  padding: 0;
}

.toast-top-center {
  right: 0;
  top: 0;
  width: @t-width1;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: @t-width1;
}

.toast-top-full-width {
  right: 0;
  top: 0;
  width: @t-width1;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: @t-width1;
}

.toast-top-left {
  left: 12px;
  top: 12px;
}

.toast-top-right {
  right: 12px;
  top: 12px;
}

.toast-bottom-right {
  bottom: 12px;
  right: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

#toast-container > div {
  -moz-border-radius: 0;
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, .1);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  -webkit-border-radius: 0;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, .1);
  background-color: @t-color1;
  background-position: 15px center;
  background-repeat: no-repeat;
  border-radius: 0;
  border: 1px solid #D8D8D8;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .1);
  color: #88909A;
  filter: alpha(opacity=99);
  margin: 0 0 6px;
  opacity: .99;
  overflow: hidden;
  padding: 15px;
  position: relative;
  width: @t-width0;
}

#toast-container > {
  &:hover {
    cursor: pointer;
  }
}

#toast-container > .toast-info {
}

#toast-container > .toast-error {
}

#toast-container > .toast-success {
}

#toast-container > .toast-warning {
}

#toast-container.toast-top-center > div {
  margin: auto;
  width: @t-width0;
}

#toast-container.toast-bottom-center > div {
  margin: auto;
  width: @t-width0;
}

#toast-container.toast-top-full-width > div {
  margin: auto;
  width: @t-width2;
}

#toast-container.toast-bottom-full-width > div {
  margin: auto;
  width: @t-width2;
}

.toast {
  color: @t-color0;
}

.toast-progress {
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  background-color: @t-color0;
  background-color: @t-color0;
  bottom: 0;
  bottom: 0;
  filter: alpha(opacity=40);
  filter: alpha(opacity=70);
  height: @t-height1;
  height: @t-height1;
  left: 0;
  left: 0;
  opacity: 0.4;
  opacity: 0.7;
  position: absolute;
  position: absolute;
}

.toast-success {
  color: @t-color2;
  .toast-progress {
    background-color: @t-color2;
  }
  .leap-icon {
    color: @t-color2;
  }
}

.toast-error {
  color: @t-color3;
  .toast-progress {
    background-color: @t-color3;
  }
  .leap-icon {
    color: @t-color3;
  }
}

.toast-info {
  color: @t-color5;
  .toast-progress {
    background-color: @t-color5;
  }
  .leap-icon {
    color: @t-color5;
  }
}

.toast-warning {
  color: @t-color4;
  .toast-progress {
    background-color: @t-color4;
  }
  .leap-icon {
    color: @t-color4;
  }
}

.leap-notify > h5 {
  display: block;
  color: @brand-primary;;
  font-weight: 600;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 90%;
}

.leap-notify > .leap-icon {
  display: block;
  float: left;
  font-size: 40px;
  margin: 25px 30px 10px 10px;
  width: 10%;
}

.leap-notify > p {
  color: @brand-primary;
  display: block;
  float: left;
  font-size: 12px;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 10px;
  width: 75%;
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container {
    margin-left:12px;
    & > div {

      margin:0;
      width: 100%;

      .toast-close-button {
        right: -0.0em;
        top: -0.6em;
      }

    }

  }

  .leap-notify > p {
    width: 65%;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container {
    margin-left:12px;
    & > div {

      margin:0;
      width: 100%;

      .toast-close-button {
        right: -0.0em;
        top: -0.6em;
      }

    }

  }

  .leap-notify > p {
    width: 65%;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container {
    margin-left:12px;
    & > div {

      margin:0;
      width: 100%;

      .toast-close-button {
        right: -0.0em;
        top: -0.6em;
      }

    }

  }

  .leap-notify > p {
    width: 65%;
  }
}
