// Tabs
// --------------------------------------------------

.nav-tabs {
  border-bottom-width: 2px;
  font-weight: 600;
  color: @color-grey;
  text-transform: uppercase;
  margin-bottom: 20px;

  & > li {
    margin-bottom: -2px;

    & > a {
      color: @color-grey;
      border: none;
      border-bottom: 4px solid transparent;
      padding-top: 12px;
      padding-bottom: 12px;
      .border-radius(0);
      .transition(all, 0.3s, ease);
    }

    &.active > a, &.active > a:hover, &.active > a:focus {
      color: @color-green;
      cursor: default;
      border: none;
      border-bottom: 4px solid @color-green;
      background-color: transparent;
    }

    & > a:hover, &.open > a {
      color: @color-grey-dark;
    }

    & > a:hover, a:focus {
      border-bottom: 4px solid @color-grey-light-3;
      padding-top: 12px;
    }

  }

  &.leap-nav-tabs-flipped {
    border-bottom-width: 0px;
    border-top: 2px solid @color-grey-light-3;

    & > li {
      margin-top: -2px;
      margin-bottom: 0px;

      a {
        border: none;
        border-top: 4px solid transparent;
      }

      &.active > a, &.active > a:hover, &.active > a:focus {
        border: none;
        border-top: 4px solid @color-green;
      }

      & > a:hover, a:focus {
        border-top: 4px solid @color-grey-light-3;
        padding-top: 12px;
      }

    }

  }

}

.nav-tabs.nav-justified {
  border-bottom-width: 2px;
  font-weight: 600;
  color: @color-grey;
  text-transform: uppercase;
  margin-bottom: 20px;

  & > li {
    margin-bottom: -2px;

    & > a {
      color: @color-grey;
      border: none;
      border-bottom: 2px solid @color-grey-light-3;
      padding-top: 12px;
      padding-bottom: 14px;
      .border-radius(0);
      .transition(all, 0.3s, ease);
    }

    &.active > a, &.active > a:hover, &.active > a:focus {
      color: @color-green;
      cursor: default;
      border: none;
      border-bottom: 4px solid @color-green;
      background-color: transparent;
      padding-bottom: 12px;
    }

    & > a:hover, &.open > a {
      color: @color-grey-dark;
    }

    & > a:hover, a:focus {
      border-bottom: 4px solid @color-grey-light-3;
      padding-bottom: 12px;
    }

  }

  &.leap-nav-tabs-flipped {
    border: 0;

    & > li {
      margin-top: -2px;
      margin-bottom: 0px;

      a {
        border: none;
        border-top: 2px solid @color-grey-light-3;
        padding-top: 14px;
      }

      &.active > a, &.active > a:hover, &.active > a:focus {
        border: none;
        padding-top: 12px;
        border-top: 4px solid @color-green;
      }

      & > a:hover, a:focus {
        border-top: 4px solid @color-grey-light-3;
        padding-top: 12px;
      }

    }

  }

}

// Pills
// --------------------------------------------------

.nav-pills {

  & > li {
    font-weight: 600;
  }

}

// Navs
// --------------------------------------------------

.leap-nav-stacked {
  border-top: 2px solid @color-grey-light-3;

  & > li + li {
    margin: 0;
  }

  li {
    margin: 0;
    border-bottom: 2px solid @color-grey-light-3;

    & > a {
      color: @color-grey;
      padding-top: 12px;

      & > h5 {
        text-transform: uppercase;
        color: @color-grey;
        margin: 0;
      }
      & > small {
        font-size: 13px;
        font-weight: 600;
      }

      &:hover, &:focus {
        background-color: @color-grey-light-1;

        & > h5 {
          color: @color-grey-dark;
        }
      }
    }

    &.active {
      background-color: white;
      & > a > h5 {
        color: @color-green;
      }
    }
  }
}

// Navbar
// --------------------------------------------------

.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
  background-color: darken(@color-grey-dark, 6.5%);
}

.navbar-inverse .navbar-toggle {
  border-color: darken(@color-grey-dark, 6.5%);
}

.leap-navbar {
  overflow: hidden;

  .border-radius(0);
  border: none;

  .navbar-brand {
    color: @color-green;
    font-size: 32px;
    padding: 9px 10px 9px 20px;
    text-shadow: none;
    .transition(all, 0.3s, ease);

    &:hover {
      color: lighten(@color-green, 5%);
    }

  }

  .nav > li {

    &.active {

      a {
        //text-shadow: 0 0 0 rgba(246, 145, 57, .7);
      }

      & > a:after {
        width: 0;
        height: 0;
        border: 0 solid transparent;
        border-right-width: 5px;
        border-left-width: 5px;
        border-bottom: 5px solid @color-grey-light-1;
        margin-left: -5px;
        bottom: -1px;
        left: 50%;
        content: " ";
        position: absolute;
        pointer-events: none;
        .hidden-xs();
      }

    }

    & > a {
      font-size: 20px;
      line-height: 21px;
      padding: 14px 15px 14px 15px;
      font-weight: 300;
      text-decoration: none;
      .transition(all, 0.3s, ease);
      text-shadow: 0 0 0 rgba(255, 255, 255, .7);
      position: relative;

      &:hover {
        color: @color-white;
        //text-shadow: 0 0 0 rgba(246, 145, 57, .7);
      }

      & > i {
        position: relative;
        top: 2px;
      }

      .leap-nav-icon-20 {
        font-size: 20px;
        line-height: 21px;
      }

      .leap-nav-icon-22 {
        font-size: 22px;
        line-height: 14px;
      }

    }
  }

  .divider-vertical {
    height: 42px;
    margin: 4px 9px;
    border-right: 1px solid darken(@color-green, 6.5%);
    border-left: 1px solid darken(@color-green, 6.5%);
    .hidden-xs();
  }

}

// Card Bar
// --------------------------------------------------

.leap-cards-nav {
  position: relative;
  display: block;
  text-align: center;
  background-color: @color-grey-light-2;

  .leap-cards-nav-pager {
    position: absolute;
    left: 40px;
    top: 16px;
  }

  .leap-cards-nav-control {
    text-align: center;
    height: 50px;

    h4 {
      padding: 0 20px;
      display: inline;
      bottom: 1px;
      position: relative;
      color: @color-green;
    }

    & > a {
      font-size: 36px;
      line-height: 36px;
      padding: 0 10px;
      position: relative;
      top:8px;
      color: @color-grey-dark;
      .transition(all, 0.3s, ease);

      &:hover, &:focus {
        color: @color-green;
        text-decoration: none;
      }

      &.disabled, &.disabled:hover {
        color: @color-grey-light-3;
        cursor: default;
        text-decoration: none;
      }
    }

  }
}

