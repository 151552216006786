.label {
  font-weight: 500;
  .border-radius(15px);
  padding: 5px 15px;

  &.label-default {
    background-color: @color-grey;
  }
}

.leap-label {
  color: @color-white;
  border: 2px solid @color-white;
  .border-radius(15px);
  background-color: @color-white;
  text-shadow: none;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: -0.03em;
  text-align: center;
  display: inline-block;
  min-width: 140px;
  font-weight: 700;
  padding: 7px 15px;

  &.leap-grey-label {
    color: @color-grey;
    border: 2px solid @color-grey;
  }

  &.leap-orange-label {
    color: @brand-warning;
    border: 2px solid @brand-warning;
  }

  &.leap-red-label {
    color: @brand-danger;
    border: 2px solid @brand-danger;
  }

  &.leap-darkgrey-label {
    color: @color-grey-dark;
    border: 2px solid @color-grey-dark;
  }

  &.leap-green-label {
    color: @color-green;
    border: 2px solid @color-green;
  }

}

.leap-staff-label {
  color: @color-white;
  border: 2px solid lighten(@color-grey, 15%);
  background-color: lighten(@color-grey, 15%);
  .border-radius(30px);
  text-align: center;
  letter-spacing: -0.03em;
  width: 38px;
  height: 38px;
  display: inline-block;
  font-weight: 400;
  line-height: 34px;

  &.ch2 {
    font-size: 18px;
  }

  &.ch3 {
    font-size: 15px;
  }

  &.leap-staff-small-label {
    width: 29px;
    height: 29px;

    &.ch2 {
      font-size: 15px;
      line-height: 24px;
    }

    &.ch3 {
      font-size: 12px;
      line-height: 26px;
    }

  }

}

