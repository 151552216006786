h1 {
  font-weight: 400;
  letter-spacing: -0.07em;
  -webkit-font-smoothing: antialiased;
  //text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.04);
  font-size: 30px;
  display: inline;

  small {
    font-weight: 400;
    letter-spacing: -0.03em;
  }

  .leap-back-button, .leap-next-button {
    width: 60px;
    margin-top: 7px;
  }

  a {
    text-decoration:none;
    transition: all 0.3s ease;

    &:hover {
      color:@color-green;
    }
  }

}

h2 {
  font-weight:400;
  letter-spacing:-0.05em;
  font-size:25px;
  text-transform:uppercase;
}

h3 {
  font-weight:400;
  letter-spacing:-0.04em;
  font-size:22px;
  text-transform:uppercase;
}

h4 {
  font-weight:600;
  letter-spacing:-0.04em;
  font-size:18px;
}

h5 {
  font-weight:600;
  letter-spacing:-0.03em;
  font-size:16px;
}

h6 {
  font-weight:600;
  letter-spacing:-0.03em;
  font-size:14px;
}

h1 > small, h2 > small, h3 > small, h4 > small, h5 > small, h6 > small {
  color: @color-grey;
}
