footer {
  width: 100%;
  height: 50px;
  color: @color-grey;
  background-color: @color-grey-light-1;
  text-align: left;
  font-size: 12px;
  border-top: 2px solid @color-grey-light-3;

  &> div {
    padding:15px;

    ul.leap-footer-links {
      margin: 0;
      list-style: none;
      float: right;

      li {
        display: list-item;
        text-align: -webkit-match-parent;
        float: left;

        a {
          color: @color-grey;
          text-decoration: none;
          transition: all 0.3s ease;

          &:hover {
            color: @color-green;
          }
        }

      }

      .divider-vertical {
        height: 13px;
        margin: 3px 9px;
        border-right: 1px solid @color-grey-light-3;
        border-left: 1px solid @color-grey-light-3;
      }

      .leapicon-leap {
        font-size: 16px;
        margin-left: 10px;
        text-align: left;
        opacity: .5;
        position: relative;
        top: 1px;
      }

    }

  }

}

