.breadcrumb {
  font-weight:600;
  letter-spacing:-0.03em;
  font-size:12px;
  text-transform:uppercase;
  margin:0;
  padding:12px 25px 10px 25px;
  .border-radius(0);
  text-shadow:none;
  border-bottom:2px solid @color-grey-light-3;
  color: @color-grey;

  &> li > a {
    &:hover {
      color: @color-green;
    }
  }

  .breadcrumb-home {
    font-size:16px;
    line-height: 10px;
    margin-right: 5px;
    position:relative;
    top:1px;
  }

  .breadcrumb-separator {
    font-size:24px;
    line-height: 10px;
    margin:0 5px;
    position:relative;
    top:5px;
  }

  .breadcrumb-status {
    &> a {
      font-size:16px;
      line-height: 10px;
      margin-left: 5px;
      color: @color-grey;
      text-decoration: none;
      position:relative;
      top:1px;
    }
  }

  &>li+li:before {
    display:none;
  }
}