.modal {

  .modal-dialog {
    -webkit-transform: translate(0, -5%);
    -ms-transform: translate(0, -5%);
    transform: translate(0, -5%);
    .transition(transform, .3s, ease-out);

    .modal-header {
      button > i {
        position: relative;
        top: 3px;
        left: 3px;
      }

      .modal-title {
        font-weight: 500;
      }
    }

    .modal-content {
      .border-radius(0);
      .box-shadow(0, 1px, 5px, 0.1);
      border: none;
    }

  }

}